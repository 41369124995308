import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'call',
    loadChildren: () => import('./modals/call/call.module').then( m => m.CallPageModule)
  },
  {
    path: 'historical',
    loadChildren: () => import('./historical/historical.module').then( m => m.HistoricalPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'bookingdesk',
    loadChildren: () => import('./bookingdesk/bookingdesk.module').then( m => m.BookingdeskPageModule)
  },
  {
    path: 'ratealert',
    loadChildren: () => import('./ratealert/ratealert.module').then( m => m.RatealertPageModule)
  },
  {
    path: 'livechart',
    loadChildren: () => import('./livechart/livechart.module').then( m => m.LivechartPageModule)
  },
  {
    path: 'compare',
    loadChildren: () => import('./compare/compare.module').then( m => m.ComparePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
